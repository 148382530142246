<template>
  <CRow v-if="can('bank_user')">
    <CCol>
      <CCard>
        <CCardHeader><h2>Архів заявок</h2></CCardHeader>
        <CCardBody>
          <CRow v-if="is_loader" class="justify-content-center">
            <CSpinner color="primary" style="width:4rem;height:4rem;"/>
          </CRow>

          <template v-if="orders != null">
            <CDataTable
                hover
                striped
                :items="orders"
                :fields="fieldsTenders"
                index-column
                :items-per-page-select="{label:'Кількість на сторінці: ', values:[5,10,20,30,50,100,200]}"
                :items-per-page="perPage"
                :table-filter="{lazy: false, placeholder: 'Знайти...', label: ' ', external:true}"
                @pagination-change="getPerPage"
                @update:table-filter-value="getTableFilter"
                clickable-rows
                @update:sorter-value="getColumnSorter"
                :sorter="{external:false, resetable:false}"
                :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'За результатами пошуку нічого не знайдено' }"
                @row-clicked="rowClicked"
            >
              <!--                :sorter-value="{column:'created_at',  asc:false}"-->

              <!--              @update:table-filter-value ='getFilter'-->
              <template #created_at="data">
                <td>
                  {{ timeConvertTender(data.item.created_at) }}
                </td>
              </template>
              <template #date_connection="data">
                <td>
                  {{ timeConvertTender(data.item.date_connection) }}
                </td>
              </template>
              <template #end_tender="data">
                <td>
                  <span v-if="Number(data.item.end_tender) !== 0">{{ timeConvertTender(data.item.end_tender) }}</span>
                </td>
              </template>
              <template #branch_a_address="data">
                <td>
                  <span class="font-weight-bold">{{ data.item.merchant || data.item.branch_a }}</span>
                  {{ data.item.branch_a_address }}
                </td>
              </template>
              <template #merchant>
                <td></td>
              </template>
              <template #branch_a>
                <td></td>
              </template>
              <template #id>
                <td></td>
              </template>
              <template #connect_type="data">
                <td>
                  {{ data.item.connect_type }}
                </td>
              </template>
              <template #channel_purpose="data">
                <td>
                  {{ data.item.channel_purpose }}
                </td>
              </template>
              <template #channel_speed="data">
                <td>
                  {{ data.item.channel_speed }}
                </td>
              </template>
              <template #branch_b_address="data">
                <td>
                 <span v-if="data.item.connect_type !== 'Інтернет'">
                   <span class="font-weight-bold">{{ data.item.branch_b }}</span> {{ data.item.branch_b_address }}
                 </span>
                </td>
              </template>
              <template #status="data">
                <td>
                  <CBadge class="p-2 " :color="getBadgeStatus(data.item.status)">
                    {{ statusTender(data.item.status) }}
                  </CBadge>
                </td>
              </template>
            </CDataTable>
            <CPagination
                v-if="countPage>=1"
                align="center"
                :pages="Math.ceil(countPage)"
                :active-page.sync="activePage"
            />
          </template>
          <template v-else>
            <h4>Ще немає інформації по жодному тендері! :(</h4>
          </template>
        </CCardBody>
        <CCardFooter></CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios"
import statusTenderAndPropositionMixin from '../../mixins/statusTenderAndPropositionMixin'
import timeConvertMixin from '@/mixins/timeConvertMixin'
import {can} from "@/rules/permissions";
// import Tender from "@/views/tenders/Tender";

export default {
  // components: {Tender},
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  name: "AdminHistory",
  data: () => {
    return {
      columnSorter: {
        asc: false,
        column: 'created_at',
      },
      newPerPage: 20,
      perPage: 20,
      activePage: 1,
      is_loader: false,
      tableFilterVal: '',
      countPage: 0,
      orders: [{
        channel_purpose: "",
        connect_type: "",
        branch_a: "",
        branch_a_address: "",
        branch_b: "",
        branch_b_address: "",
        consumer_type: "",
        channel_speed: 0,
        date_connection: "",
        channel_required: "",
        coordinates: [],
        icons: [],
        extra: "",
        state_id: "",
      }],
      fieldsTenders: [
        {key: 'created_at', label: 'Старт тендеру'},
        {key: 'branch_a_address', label: 'Адреса точки А'},
        {key: 'branch_b_address', label: "Адреса точки Б"},
        {key: 'channel_purpose', label: 'Призначення'},
        {key: 'connect_type', label: 'Тип каналу'},
        {
          key: 'channel_speed',
          label: "Швидкість, Мбіт/с",
          _style: 'width:7%',
        },
        {key: 'end_tender', label: 'Закінчення тендеру', _style: 'width:5%'},
        {
          key: 'status',
          label: 'Статус тендера ',
          _style: 'width:5%',
        },
        {key: 'branch_a', label: '', sorter: false,},
        {key: 'id', label: '', sorter: false, _style: 'display:none;',},
      ],
    }
  },
  methods: {
    can,
    getPerPage(e) {
      this.newPerPage = e;
    },
    getTenderHistory(limit, page, columnSorter, tableFilter) {
      let self = this;
      let sort = null;
      let tabFilter = '';
      self.is_loader = true;
      sort = columnSorter.asc

      if (sort) {
        sort = 'asc'
      } else {
        sort = 'desc'
      }
      if (tableFilter) tabFilter = `&search=${tableFilter}`

      axios.get(`/api/orders/history?limit=${limit}&sort=${sort}&order_by=${columnSorter.column}&offset=${limit * (page - 1)}${tabFilter}`)
          .then(function (response) {
            self.orders = response.data ?? [];
            self.is_loader = false;
          })
          .catch(function () {
            self.is_loader = true;
          })
    },
    rowClicked(item) {
      let location = {name: 'TendersPreview', params: {id: item.id.toString()}}
      // let link = this.$router.resolve({path: `/tenders/${item.id.toString()}`})
      let link = this.$router.resolve(location)
      window.open(link.href, '_blank');
    },
    getTendersPagination(tableFilter) {
      let self = this;
      let tabFilter = '';

      if (tableFilter) tabFilter = `?search=${tableFilter}`
      axios.get(`/api/orders/history/count${tabFilter}`)
          .then(function (response) {
            self.countPage = response.data / self.newPerPage;
          })
          .catch(function () {
            self.countPage = 0
          })
    },
    isStart(one, two, tree, four) {
      this.is_loader = true;
      this.getTenderHistory(one, two, tree, four);
      this.getTendersPagination(four);
    },
    // getFilter(e){
    //     //table filter
    //     console.log(e)
    // },
    getColumnSorter(e) {
      //column sorter
      this.columnSorter = e;
    },
    getTableFilter(e) {
      if (e === '') {
        this.activePage = 1;
        this.tableFilterVal = '';
        return this.isStart(this.newPerPage, this.activePage, this.columnSorter);
      }
      if (e.length >= 4 && e.length <= 150) {
        this.tableFilterVal = e;
        this.activePage = 1;
        return this.isStart(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
      }
    }
  },
  created() {
    this.isStart(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal)
  },
  computed: {
    tableColumnSorter() {
      return {
        columnSorter: {
          column: this.columnSorter.column,
          asc: this.columnSorter.asc
        }
      }
    }
  },
  watch: {
    'activePage': function () {
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    },

    'newPerPage': function () {
      this.activePage = 1;
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    },

    tableColumnSorter() {
      this.activePage = 1;
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    }
  }
}
</script>
